import {
    CartQuery as SourceCartQuery,
} from 'SourceQuery/Cart.query';

/** @namespace AlmusbahblendPwa/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    // TODO implement logic

    getGiftMessageQuery(cartId) {
        console.log("received cartId: ", cartId);
        return new Field('getGiftMessage')
        .addArgument('cart_id', 'String', cartId)
        .addFieldList(this._getGiftMessageFields());
    }

    _getGiftMessageFields() {
        return [
            'to',
            'from',
            'message',
        ];
    }

    getApplyGiftMessageMutation(cartId, giftMessage) {
        const input = {
            'cart_id': cartId,
            'gift_message': giftMessage
        };
        return new Mutation('setGiftMessageOnCart')
        .addArgument('input', 'SetGiftMessageOnCartInput', input)
        .addField(this._getGiftMessageSuccessField());
    }

    getRemoveGiftMessageMutation(cartId) {
        const giftMessage = {
            'from': '',
            'to': '',
            'message': ''
        };
        const input = {
            'cart_id': cartId,
            'gift_message': giftMessage
        };
        return new Mutation('setGiftMessageOnCart')
        .addArgument('input', 'SetGiftMessageOnCartInput', input)
        .addField(this._getGiftMessageSuccessField());
    }

    _getGiftMessageSuccessField() {
        return [
            'success',
        ];
    }

}

export default new CartQuery();
